import { Controller } from 'stimulus';
import axios from 'axios';
import _last from 'lodash.last';
import _debounce from 'lodash.debounce';

export default class extends Controller {
  static targets = ['number', 'status'];

  initialize() {
    this.update = _debounce(this.update, 250);
  }

  async update() {
    try {
      const result = await axios.get(
        `https://e1hji4318e.execute-api.ap-northeast-1.amazonaws.com/production/yubin-track?track=${this.numberTarget.value}`
      );
      const statuses = result.data['履歴'];
      this.statusTarget.value = _last(statuses)['配送履歴'];
    } catch (e) {
      const message = e.response.data.message;
      this.statusTarget.value = message;
    }
  }
}
