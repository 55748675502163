import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['assessment', 'checkbox', 'radio', 'submit'];

  click(event) {
    const assessment = parseInt(this.assessmentTarget.value, 10);
    const checked = this.checkboxTarget.checked;
    if ((isNaN(assessment) || assessment <= 0) && checked) {
      if (confirm('\n\n0円の案件ですが自動承認して大丈夫ですか？\n\n')) {
        this.submitTarget.click();
      } else {
        event.preventDefault();
        event.stopPropagation();
      }
    }
  }

  check(event) {
    const checked = event.currentTarget.checked;
    this.radioTargets.forEach((radio) => {
      if (radio.value === 'tel') {
        radio.disabled = checked;
      }
    });
  }

  radio(event) {
    if (event.currentTarget.value === 'tel') {
      this.checkboxTarget.checked = false;
      this.checkboxTarget.disabled = true;
    } else {
      this.checkboxTarget.disabled = false;
    }
  }
}
