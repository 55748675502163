import * as Sentry from '@sentry/browser';

export function handleError(error) {
  if (error.message) {
    alert(error.message);
  } else {
    alert(error);
  }
  console.error(error);
  Sentry.captureException(error);
}
