import { Controller } from 'stimulus';
import crumbs from 'crumbsjs';

export default class extends Controller {
  static targets = ['link', 'active', 'nonActive'];
  static classes = ['hidden'];

  initialize() {
    this.couponShow = crumbs.get('coupon_toggle') === 'show';
    this.couponShow ? this.display() : this.hidden();
  }

  toggle(event) {
    event.preventDefault();

    if (this.couponShow) {
      this.hidden();
      crumbs.delete('coupon_toggle');
    } else {
      this.display();
      crumbs.set('coupon_toggle', 'show');
    }
    this.couponShow = !this.couponShow;
  }

  display() {
    this.linkTarget.innerText = '有効なクーポンのみ表示';
    if (this.hasNonActiveTarget) {
      Array.from(this.nonActiveTargets).forEach((target) =>
        target.classList.remove(this.hiddenClass)
      );
    }
  }

  hidden() {
    this.linkTarget.innerText = 'すべて表示';
    if (this.hasNonActiveTarget) {
      Array.from(this.nonActiveTargets).forEach((target) =>
        target.classList.add(this.hiddenClass)
      );
    }
  }
}
