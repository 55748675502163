import { Controller } from 'stimulus';

export default class extends Controller {
  static values = { no: String };
  attr = 'satei_no';

  connect() {
    if (!this.noValue) {
      this.element.hidden = true;
    }
  }

  updated(event) {
    if (this.attr !== event.detail.attr) {
      return;
    }

    const value = event.detail.value;
    if (value.length > 0) {
      this.element.hidden = false;
      this.element.href = `https://manage.aws.yamato-gp.net/satei/?transaction_id=${value}`;
    } else {
      this.element.hidden = true;
    }
  }
}
