import { Controller } from 'stimulus';
import { Printd } from 'printd';
import commonStyle from '!!raw-loader!../../styles/print/common.css';
import reportStyle from '!!raw-loader!../../styles/print/report.css';
import addressStyle from '!!raw-loader!../../styles/print/address_list.css';
import '../../styles/print/common.css';
import '../../styles/print/report.css';
import '../../styles/print/address_list.css';

export default class extends Controller {
  static targets = ['printable'];

  connect() {
    if (this.hasPrintableTarget) {
      this.print();
    }
  }

  print() {
    const d = new Printd({
      parent: this.printableTarget,
    });
    d.print(this.printableTarget, [commonStyle, reportStyle, addressStyle]);
  }

  click(event) {
    event.preventDefault();
    this.print();
  }
}
