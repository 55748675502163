import { Controller } from 'stimulus';
import { Printd } from 'printd';
import commonStyle from '!!raw-loader!../../styles/print/common.css';
import jobticketStyle from '!!raw-loader!../../styles/print/jobticket.css';
import '../../styles/print/common.css';
import '../../styles/print/jobticket.css';

export default class extends Controller {
  print(event) {
    event.preventDefault();
    const d = new Printd({
      parent: document.getElementById('printable'),
    });
    d.print(document.getElementById('printable'), [
      commonStyle,
      jobticketStyle,
    ]);
  }
}
