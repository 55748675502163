import { Controller } from 'stimulus';
import axios_rails from '../../lib/axios_rails';

export default class extends Controller {
  static targets = ['source'];

  initialize() {
    this.cid = parseInt(this.data.get('cid'));
  }

  async copy(event) {
    event.preventDefault();

    try {
      const result = await confirmPromise(
        '顧客の登録期間はボタンをクリックしてから48時間以内になります。'
      );
      if (result) {
        const response = await axios_rails().post(
          `/kanri/clients/${this.cid}/line_registration`
        );
        this.sourceTarget.innerHTML = response.data;
        setTimeout(async () => {
          await navigator.clipboard.writeText(this.sourceTarget.innerText);
        }, 250);
      }
    } catch (error) {
      console.error(error);
    }
  }
}

function confirmPromise(message) {
  return new Promise((resolve, reject) => {
    const confirmed = window.confirm(message);
    return confirmed ? resolve(true) : reject(false);
  });
}
