import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['source'];

  async copy(event) {
    event.preventDefault();
    setTimeout(async () => {
      await navigator.clipboard.writeText(this.sourceTarget.textContent.trim());
    }, 250);
  }
}
