import { Controller } from 'stimulus';
import { Printd } from 'printd';
import axios_rails from '../../lib/axios_rails';
import { handleError } from '../../lib/error';
import commonStyle from '!!raw-loader!../../styles/print/common.css';
import reportStyle from '!!raw-loader!../../styles/print/report.css';
import honninStyle from '!!raw-loader!../../styles/print/honnin.css';
import '../../styles/print/common.css';
import '../../styles/print/report.css';
import '../../styles/print/honnin.css';

export default class extends Controller {
  static targets = ['printable', 'trigger'];
  static values = { url: String };

  async connect() {
    if (this.triggerTarget) {
      this.triggerTarget.innerHTML = 'データ取得中...';
      this.triggerTarget.classList.add('disabled');
      if (this.urlValue) {
        await this.fetch();
        this.triggerTarget.innerHTML = '本人確認書類を印刷';
        this.triggerTarget.classList.remove('disabled');
        this.print();
      }
    }
  }

  print() {
    const d = new Printd({
      parent: this.printableTarget,
    });
    d.print(this.printableTarget, [commonStyle, reportStyle, honninStyle]);
  }

  click(event) {
    event.preventDefault();
    this.print();
  }

  async fetch() {
    try {
      const res = await axios_rails().get(this.urlValue);
      this.printableTarget.innerHTML =
        res.data['honnin_kakunin'].join() + res.data['report'];
    } catch (error) {
      handleError(error);
    }
  }
}
