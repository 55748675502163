import { Controller } from 'stimulus';

export default class extends Controller {
  check(event) {
    if (event.currentTarget.value === 'pay_bank') {
      alert(
        '※重要※申込用紙の口座番号は確認しましたか？システム内の顧客情報と申込用紙の口座番号が合っているか必ず確認をしてください。'
      );
    }
  }
}
