import { Controller } from 'stimulus';
import axios_rails from '../../lib/axios_rails';
import { handleError } from '../../lib/error';

export default class extends Controller {
  static values = { trade: Number };

  async update(event) {
    try {
      event.preventDefault();
      if (
        window.confirm('買取の返答を強制的に拒否にします。よろしいでしょうか？')
      ) {
        const res = await axios_rails().post(
          `/kanri/trades/${this.tradeValue}/after_refuse`
        );
        if (res.status === 200) {
          location.reload();
        }
      }
    } catch (error) {
      handleError(error);
    }
  }
}
