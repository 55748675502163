import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['textarea'];

  initialize() {
    this.textareaTarget.hidden = true;
  }

  change(event) {
    const radioVal = event.currentTarget.value;
    this.textareaTarget.hidden = radioVal === 'all';
  }
}
