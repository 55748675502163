import MockAdapter from 'axios-mock-adapter';

export function sateiDbMock(axios) {
  const mock = new MockAdapter(axios, { onNoMatch: 'passthrough' });
  mock
    .onPost('https://staging.manage.aws.yamato-gp.net/transactions/api/')
    .reply(200, {
      id: 39893,
      get_method_display: '宅配買取',
      get_customer_display: 'リピーター',
      name: 'test',
      code: '20210302_min736',
      email: 'test@example.com',
      rate: 20,
      campaign_rate: 20,
      memo: '',
      user: 1,
      total_amount: 0,
      total_price: 0,
      total_rate: 0,
      count_statements: 0,
      total_applicable_amount: 0,
      total_applicable_rate: 0,
      overview: 'toy',
      get_overview_display: 'おもちゃ',
      status: 'open',
      get_status_display: '取引中',
      created_at: '2021-03-02T11:50:47.493540+09:00',
      updated_at: '2021-03-02T11:50:47.493577+09:00',
      user_name: 'user',
    });
}

export function sateiDbAssessmentMock(axios) {
  const mock = new MockAdapter(axios, { onNoMatch: 'passthrough' });
  const baseUrl = 'https://staging.manage.aws.yamato-gp.net/transactions/api/';
  mock.onGet(new RegExp(`${baseUrl}/*`)).reply(200, {
    id: 111,
    store: 1,
    shop: 1,
    customer: 'new',
    get_method_display: '店頭買取',
    method: 'shop',
    get_customer_display: '新規',
    name: '藤原理沙',
    code: '20200515_mteUZsQR',
    email: '6d.nyanko.77@gmail.com',
    rate: 30,
    campaign_rate: 20,
    memo: '新規 住民票あり 領収書見当たらず',
    user: 9,
    total_price: 28041,
    total_rate: 34,
    count_statements: 12,
    total_applicable_amount: 11530,
    total_applicable_rate: 41,
    overview: '',
    get_overview_display: '選択してください',
    status: 'closed',
    get_status_display: 'closed',
    created_at: '2020-05-15T15:53:50.127057+09:00',
    updated_at: '2020-07-13T11:31:00.572978+09:00',
    user_name: '金 志遠',
  });
}
