import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['message', 'count'];

  initialize() {
    this.countTarget.innerText = 0;
  }

  setExample(event) {
    const message = event.target.value;
    if (message.length > 0) {
      this.messageTarget.value = message;
      this.countTarget.innerText = message.length;
    }
  }

  countText(event) {
    const count = event.target.value.length;
    this.countTarget.innerText = count;
  }
}
