import axios from 'axios';
import Rails from '@rails/ujs';
import qs from 'qs';

export default function () {
  return axios.create({
    headers: {
      'X-CSRF-TOKEN': Rails.csrfToken(),
      'X-Requested-With': 'XMLHttpRequest',
    },
    paramsSerializer: function (params) {
      return qs.stringify(params, { arrayFormat: 'brackets' });
    },
  });
}
