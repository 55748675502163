import { Controller } from 'stimulus';
import axios from 'axios';
import axios_rails from '../../lib/axios_rails';
import { handleError } from '../../lib/error';
import { sateiDbMock } from '../../lib/satei_db_mock';

if (process.env.RAILS_ENV === 'test') {
  sateiDbMock(axios);
}

export default class extends Controller {
  static values = {
    name: String,
    email: String,
    campaign: Number,
    store: String,
    memo: String,
    env: String,
    updateUrl: String,
    customer: String,
    userEmail: String,
  };

  apiHost() {
    if (this.envValue === 'production') {
      return 'https://manage.aws.yamato-gp.net';
    } else {
      return 'https://staging.manage.aws.yamato-gp.net';
    }
  }

  postUrl() {
    return `${this.apiHost()}/transactions/api/`;
  }

  itemUrl(id) {
    // return 'https://www.yahoo.co.jp/';
    return `${this.apiHost()}/satei/?transaction_id=${id}`;
  }

  storeId() {
    return this.storeValue === 'toys' ? 1 : 2;
  }

  postParams() {
    return {
      store_id: this.storeId(),
      method: 'delivery',
      name: this.nameValue,
      email: this.emailValue,
      rate: 20,
      campaign_rate: this.campaignValue,
      memo: this.memoValue,
      status: 'open',
      overview: 'toy',
      customer: this.customerValue,
      user_email: this.userEmailValue,
    };
  }

  async update_trade_satei_no(satei_no, dealing_id) {
    return await axios_rails().patch(this.updateUrlValue, {
      trade: { satei_no: satei_no, dealing_id: dealing_id },
    });
  }

  async create(event) {
    event.preventDefault();
    try {
      const api_res = await axios.post(this.postUrl(), this.postParams());
      if (api_res.status === 200 || api_res.status === 201) {
        const satei_no = api_res.data.code;
        const dealing_id = api_res.data.id;
        const update_res = await this.update_trade_satei_no(
          satei_no,
          dealing_id
        );

        if (update_res.status === 200) {
          window.open(this.itemUrl(dealing_id), '_blank');
          window.location.reload();
        }
      }
      // console.log(this.postParams());
    } catch (error) {
      handleError(error);
    }
  }
}
