import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'bankTransferRequest',
    'needId',
    'pendingApprovals',
    'reportIdDoc',
    'needTrack',
    'valueless',
    'registeredMailReport',
    'registeredMailNoTrack',
    'registeredMailDone',
  ];
  static values = { enable: { type: Boolean } };

  async connect() {
    if (!this.enableValue) return;

    const res = await fetch('/kanri/side');
    const json = await res.json();

    this.bankTransferRequestTarget.innerText = json.bank_transfer_request_count;
    this.needIdTarget.innerText = json.need_id_count;
    this.pendingApprovalsTarget.innerText = json.pending_approvals_count;
    this.reportIdDocTarget.innerText = json.report_id_doc_count;
    this.needTrackTarget.innerText = json.need_track_count;
    this.valuelessTarget.innerText = json.valueless_count;
    this.registeredMailReportTarget.innerText =
      json.registered_mail_report_count;
    this.registeredMailNoTrackTarget.innerText =
      json.registered_mail_no_track_count;
    this.registeredMailDoneTarget.innerText = json.registered_mail_done_count;
  }
}
