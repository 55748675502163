import { Controller } from 'stimulus';
import axios_rails from '../../lib/axios_rails';

export default class extends Controller {
  static targets = ['label', 'input'];
  static classes = ['editing'];
  static values = { url: String, attr: String, reload: Boolean };

  connect() {
    this.originValue = this.labelTarget.innerText;
  }

  edit(event) {
    event.preventDefault();
    this.element.classList.add(this.editingClass);
  }

  leave() {
    this.element.classList.remove(this.editingClass);
  }

  async ok() {
    const value = this.inputTarget.value;

    try {
      const response = await this.put_server(value);
      if (response.status === 200) {
        this.originValue = value;
        this.labelTarget.innerText = value;
        this.dispatch_value_updated_event(value);
      }
      if (this.isValueEmpty(value)) {
        this.inputTarget.value = '';
        this.labelTarget.innerText = 'なし';
      }
    } catch (error) {
      console.error(error);
      this.labelTarget.innerText = this.originValue;
    }

    this.element.classList.remove(this.editingClass);
    if (this.reloadValue) {
      location.reload();
    }
  }

  cancel() {
    if (this.isValueEmpty(this.originValue)) {
      this.inputTarget.value = '';
      this.labelTarget.innerText = 'なし';
    } else {
      this.inputTarget.value = this.originValue;
      this.labelTarget.innerText = this.originValue;
    }
    this.element.classList.remove(this.editingClass);
  }

  async select(event) {
    const origin_label = this.labelTarget.innerText;
    const index = event.currentTarget.selectedIndex;
    const label = event.currentTarget[index].text;
    const value = event.currentTarget.value;

    this.element.classList.remove(this.editingClass);
    this.labelTarget.innerText = label;

    try {
      const response = await this.put_server(value);
      console.log(response.data);
    } catch (error) {
      this.labelTarget.innerText = origin_label;
      console.error(error);
    }
  }

  post_params(value) {
    const params = { trade: {} };
    params['trade'][this.attrValue] = value;
    return params;
  }

  isValueEmpty(value) {
    return value.length === 0 || value === 'なし';
  }

  dispatch_value_updated_event(value) {
    const value_updated_event = new CustomEvent('value-updated', {
      detail: { attr: this.attrValue, value: value },
      bubbles: true,
      cancelable: true,
    });
    document.dispatchEvent(value_updated_event);
  }

  async put_server(value) {
    return axios_rails().put(this.urlValue, this.post_params(value));
  }
}
